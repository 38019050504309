var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Grid", {
        attrs: {
          service: _vm.certifiedService,
          route_name: "certifieds",
          route_grid_path: "/emit/grid",
        },
        scopedSlots: _vm._u([
          {
            key: "gridThead",
            fn: function () {
              return [
                _c("vs-th", { key: "id", attrs: { "sort-key": "id" } }, [
                  _vm._v("ID"),
                ]),
                _c("vs-th", { key: "name", attrs: { "sort-key": "name" } }, [
                  _vm._v(_vm._s(_vm.$t("certified.name"))),
                ]),
                _c(
                  "vs-th",
                  { key: "description", attrs: { "sort-key": "description" } },
                  [_vm._v(_vm._s(_vm.$t("certified.description")))]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "gridData",
            fn: function (props) {
              return _vm._l(props.data, function (tr, indextr) {
                return _c(
                  "vs-tr",
                  { key: indextr, attrs: { data: tr } },
                  [
                    _c("vs-td", { key: "id", attrs: { width: "5%" } }, [
                      _c("div", [_vm._v(" " + _vm._s(tr.id) + " ")]),
                    ]),
                    _c("vs-td", { key: "name", attrs: { width: "30%" } }, [
                      _c("div", [_vm._v(" " + _vm._s(tr.name))]),
                    ]),
                    _c(
                      "vs-td",
                      { key: "description", attrs: { width: "40%" } },
                      [
                        _c("div", {
                          directives: [
                            {
                              name: "html-safe",
                              rawName: "v-html-safe",
                              value: tr.description,
                              expression: "tr.description",
                            },
                          ],
                        }),
                      ]
                    ),
                    _c(
                      "vs-td",
                      { attrs: { width: "10%" } },
                      [
                        _c("feather-icon", {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: "certified_emit.show",
                              expression: "'certified_emit.show'",
                            },
                            {
                              name: "tooltip",
                              rawName: "v-tooltip.top",
                              value: {
                                content: _vm.$t("certified_emit.detail_emit"),
                                delay: { show: 100, hide: 100 },
                              },
                              expression:
                                "{\n            content: $t('certified_emit.detail_emit'),\n            delay: { show: 100, hide: 100 }\n          }",
                              modifiers: { top: true },
                            },
                          ],
                          attrs: {
                            icon: "EditIcon",
                            svgClasses:
                              "h-5 w-5 mb-1 mr-3 stroke-current text-warning",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.loadEmitForm(props.data[indextr].id)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              })
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }