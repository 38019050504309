<template>
  <div>
    <Grid :service="certifiedService"
          route_name="certifieds"
          route_grid_path="/emit/grid">
      <template v-slot:gridThead>
        <vs-th key="id" sort-key="id">ID</vs-th>
        <vs-th key="name" sort-key="name">{{ $t('certified.name') }}</vs-th>
        <vs-th key="description" sort-key="description">{{ $t('certified.description') }}</vs-th>
      </template>
      <template v-slot:gridData="props">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in props.data">
          <vs-td width="5%" key="id">
            <div> {{ tr.id }} </div>
          </vs-td>
          <vs-td width="30%" key="name">
            <div> {{ tr.name }}</div>
          </vs-td>
          <vs-td width="40%" key="description">
            <div v-html-safe="tr.description"></div>
          </vs-td>
          <vs-td width="10%">
            <feather-icon
              v-permission="'certified_emit.show'"
              icon="EditIcon"
              svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-warning"
              v-tooltip.top="{
              content: $t('certified_emit.detail_emit'),
              delay: { show: 100, hide: 100 }
            }"
              @click="loadEmitForm(props.data[indextr].id)"/>
          </vs-td>
        </vs-tr>
      </template>
    </Grid>
  </div>
</template>

<script>
import CertifiedUserEmitService from '@/services/api/CertifiedUserEmitService'
import CertifiedService from '@/services/api/CertifiedService'

export default {
  props: {
    route_name: {
      type: String,
      default: 'certified_emit'
    },
  },
  data: () => ({
    selected: [],
    service: null,
    certifiedService: null,
  }),
  methods: {
    loadEmitForm(id){
      this.$router.push(`${this.route_name}/${id}/emit`)
    }
  },
  beforeMount() {
    this.service = CertifiedUserEmitService.build(this.$vs)
    this.certifiedService = CertifiedService.build(this.$vs)
  }
}
</script>
